[data-v-f1410c9e] .el-input__wrapper {
  width: var(--f1410c9e-width);
  box-shadow: var(--f1410c9e-shadow);
  background: var(--f1410c9e-background);
}
.is-focus[data-v-f1410c9e] {
  color: var(--text-color);
  background-color: var(--control-bg);
  border-color: var(--gray-500);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(104, 113, 120, 0.25);
  border-radius: 4px;
}
[data-v-f1410c9e] .form-control:focus {
  box-shadow: none;
}
.year_toggle[data-v-f1410c9e] {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.quarter-content[data-v-f1410c9e] {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  text-align: center;
  margin-top: 8px;
}
.quarter-content button[data-v-f1410c9e] {
  margin: 8px 0;
}
.quarter-content button[data-v-f1410c9e]:nth-child(1n) {
  margin-right: 8px ;
}
.quarter-content button[data-v-f1410c9e]:nth-child(2n) {
  margin-left: 8px ;
}
.quarter-content .current[data-v-f1410c9e] {
  color: #409eff;
}
