.projectLink .el-select-dropdown__item[data-v-840331f6] {
  height: 59px !important;
}
.projectLink .el-select-dropdown__item > p[data-v-840331f6] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-840331f6] {
  font-size: 12px;
  line-height: 1em;
}
