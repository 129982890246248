.multi[data-v-eb429150] {
  display: flex;
  flex-direction: column;
}
.multi > *[data-v-eb429150] {
  flex: 1;
}
.line[data-v-eb429150] {
  display: flex;
  flex-direction: row;
}
.main[data-v-eb429150] {
  flex: 1;
}
