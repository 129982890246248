.search-container[data-v-3cf5db0f] {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--padding-sm);
  min-height: 40px;
  box-sizing: content-box;
  gap: 8px;
}
.filter-input[data-v-3cf5db0f] {
  width: 160px;
}
.tree-container[data-v-3cf5db0f] {
  max-height: 500px;
  overflow-y: scroll;
}
